/*

This provides an API for capturing analytics information.
This module will send calls to our internal analytics service `/break/event' as well as Google Tag Manager (GTM)

One "gotcha" is that our internal analytics service takes eevrything in a flat key/value map
whereas GTM items have a structure:
{
  break: {}
  user: {}
}

Usage:
    import analytics from "global/AnalyticsData"

    // these methods do NOT trigger an analytics call (they could probably be deprecated)
    analytics.setUser(user)
    analytics.setBreak(brk)
    analytics.setStep(step)

    // these methods DO trigger an analytics call
    analytics.trackEvent('event_name', {extra: 'thing'})
    analytics.stepEvent('step_start', step)
    analytics.breakEvent('break_start', brk)
    analytics.userEvent('login', user)

    

*/
import { sendEvent } from 'services/analytics'
import sessionStorageWrapper from 'util/SessionStorageWrapper'

class AnalyticsData {
  constructor() {
    this._brk = { platform: '', title: ''}
    this._step = {}
    this._user = {  isGuest: true, date_of_registration: '01/01/2020 12:00:00' } 
    this._data = {
      isGuest: true
    }
  }

  setUser(user) {
    this._user = {
      uid: user.isLoggedIn ? user.id : undefined,
      age: user.isLoggedIn ? user.age : undefined,
      gender: user.isLoggedIn ? user.gender : undefined,
      state: user.isLoggedIn ? user.state : undefined,
      zip: user.isLoggedIn ? user.zip : undefined,
      isGuest: !user.isLoggedIn,
      date_of_registration: user.isLoggedIn
        ? user.registrationDate
        : '01/01/2020 12:00:00', // TODO: Big Query requires registration date. What should this default to?
      original_utm_campaign: user.isLoggedIn ? user.utm_campaign : undefined,
      original_utm_content: user.isLoggedIn ? user.utm_content : undefined,
      original_utm_source: user.isLoggedIn ? user.utm_source : undefined,
      original_utm_medium: user.isLoggedIn ? user.utm_medium : undefined,
      original_utm_term: user.isLoggedIn ? user.utm_term : undefined,

      utm_campaign:
        typeof window != 'undefined'
          ? sessionStorageWrapper.getItem('utm_campaign')
          : undefined,
      utm_content:
        typeof window != 'undefined'
          ? sessionStorageWrapper.getItem('utm_content')
          : undefined,
      utm_source:
        typeof window != 'undefined'
          ? sessionStorageWrapper.getItem('utm_source')
          : undefined,
      utm_medium:
        typeof window != 'undefined'
          ? sessionStorageWrapper.getItem('utm_medium')
          : undefined,
      utm_term:
        typeof window != 'undefined'
          ? sessionStorageWrapper.getItem('utm_term')
          : undefined,
    }

    this._data = Object.assign({}, this._data, this._user)

  }

  setBreak(brk) {
    // console.log("Set Break", brk.permaLink)
    this._brk = {
      break_id: brk ? brk.id : undefined,
      permalink: brk ? brk.permaLink : undefined,
      break_type: brk ? brk.sponsoredType : undefined,
      title: brk ? brk.title : undefined,
      platform: brk ? brk.platform : undefined,
      step_total: brk && brk.steps ? brk.steps.length : undefined,
      lang: brk ? brk.lang : undefined,
    }

    this._data = Object.assign({}, this._data, this._brk)
  }

  setStep(step) {
    // console.log("Set Step", step.type)
    const data = {
      step_id: step ? step.id : undefined,
      step_type: step ? step.type : undefined,
      step_number: step ? step.position : undefined,
    }

    this._data = Object.assign({}, this._data, data)
  }

  merge(additionalData) {
    return Object.assign({}, this._data, additionalData)
  }

  get data() {
    return this._data
  }

  /*
  INTERNAL METHOD
    breakEvent, stepEvent, and userEvent all call this
  */
  _trackEvent(eventName, additionalData) {
    return sendEvent(eventName, this.merge(additionalData))
    .then(result => {
      return new Promise((resolve, reject) => {
        resolve(result)
      })
    })
    .catch(e => {
      console.warn(e)
      return new Promise((resolve, reject) => {
        resolve()
      })
    })

  }


  /*
    Note: use breakEvent, stepEvent, or userEvent if the event is based on a break, step, or user.
  */  
  trackEvent(eventName, additionalData) {

    window && window.dataLayer && window.dataLayer.push({
      event: eventName,
    })

    // BR-4106 - do not send these events to data warehouse
    // return this._trackEvent(eventName, additionalData)
    return new Promise((resolve, reject) => {
      resolve()
    })
  }

  userEvent(eventName, user) {
    
    window && window.dataLayer && window.dataLayer.push({
      event: eventName,
      user: {
        uid: user.id,
        age: user.age,
        date_of_registration: user.registrationDate,
        is_guest: user.guest,
        zip: user.zip,
        gender: user.gender,
        state: user.state,
        original_utm_campaign: user.utm_campaign,
        original_utm_content: user.utm_content,
        original_utm_source: user.utm_source,
        original_utm_medium: user.utm_medium,
        original_utm_term: user.utm_term,
        utm_campaign: sessionStorageWrapper.getItem('utm_campaign'),
        utm_content: sessionStorageWrapper.getItem('utm_content'),
        utm_source: sessionStorageWrapper.getItem('utm_source'),
        utm_medium: sessionStorageWrapper.getItem('utm_medium'),
        utm_term: sessionStorageWrapper.getItem('utm_term'),
      },
    })

    // BR-4106 - do not send these events to data warehouse
    // if (eventName !== 'registration') {
    //   return this._trackEvent(eventName)
    // }
    return new Promise((resolve, reject) => {
      resolve()
    })

  }
  /**
   * @typedef {Object} AnalyticsEventContext
   * @property {String} context_id
   * @property {String} context
   * @property {String} result_id
   * @property {String} result
   * @property {String} context_total for items in a list, this is the total number of items
   * @property {String} context_position for items in a list, this is the item's position, e.g. quiz question 2

   * 
   * @param {String} eventName 
   * @param {Object} step 
   * @param {AnalyticsEventContext} additionalData is context @see [confluence doc on event-context](https://thedailybreak.atlassian.net/wiki/spaces/D2/pages/1263304705/Events#Event-Context). 
   * 
   * Usage:
   ```
      analytics.stepEvent('step_interaction_pq_answer_selected', step, {
      context_id: questionId,
      context: question.text,
      result_id: answerId,
      result: answer.text,
      context_total: quiz.questions.length,
      context_position: state.position,
    })
   ```

   */
  stepEvent(eventName, step, additionalData={}) {
    try {
      this.setStep(step)
      const payload = Object.assign(
        {},
        { transaction_id: sessionStorageWrapper.getItem('transaction_id') },
        additionalData,
      )
  
      if (this._brk.break_type === "sponsored") {
        // step_start BQ evenet is handled server side when the UI calls /<step>/start
        // step_completion evenet is handled server side when the UI calls /<step>/complete
        // we still want a unified external api to triggering events
        // we still want to pass these events to GTM
        if ((eventName !== 'step_start') && (eventName !== 'step_completion')) {
          this._trackEvent(eventName, payload)
        }
  
        window && window.dataLayer && window.dataLayer.push({
          event: eventName,
          break: {
            stepid: step ? step.id : undefined,
            step_type: step ? step.type: undefined,
            step_number: step ? step.position : undefined,
          },
        })
  
      }
  
    } catch (e) {
      console.warn(eventName, e)
    }

  }

  breakEvent(eventName, brk, additionalData) {
    this.setBreak(brk)
    const payload = Object.assign(
      {},
      { transaction_id: sessionStorageWrapper.getItem('transaction_id') },
      additionalData,
    )

    if (this._brk.break_type === "sponsored") {
      this._trackEvent(eventName, payload)

      window && window.dataLayer && window.dataLayer.push({
        event: eventName,
        break: {
          break_id: brk ? brk.id : undefined,
          permalink: brk ? brk.permalink : undefined,
          break_type: brk ? brk.sponsoredType : undefined,
          title: brk ? brk.title : undefined,
          platform: brk ? brk.platform : undefined,
          step_total: brk && brk.steps ? brk.steps.length : undefined,
          lang: brk ? brk.lang : undefined,
    
        }
      })
  
    }
  }
}

export default new AnalyticsData()
