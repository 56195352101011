import { doPost, doGet, doGet2, doPost2, doPost2ErrorHandling } from './common'

const isJSON = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const userTypeEnum = {
  NONE: 'none',
  GUEST: 'guest',
  REGISTERED: 'registered',
}

export const updateProfile = obj => {
  return doPost(`/user/update`, obj)
}

export const changePassword = (
  oldPassword,
  newPassword,
  newPasswordConfirm,
) => {
  try {
    let payload = {
      currentPassword: oldPassword,
      newPassword: newPassword,
      confirmNewPassword: newPasswordConfirm,
    }

    return doPost2ErrorHandling(`/user/changePassword`, payload)
  } catch (e) {
    return new Promise((resolve, reject) => {
      // eslint-disable-line no-unused-vars
      reject({
        success: false,
        status: -1,
        message:
          'Unable to communicate with server. Please check your internet connection.',
      })
    })
  }
}

// step 1: user requests their password to be reset
export const sendResetPasswordEmail = ({
  email,
  recaptchaToken,
  turnstileToken,
}) => {
  let payload = {
    email,
    recaptchaToken,
    turnstileToken,
  }

  if (!recaptchaToken && !turnstileToken) {
    throw new Error('Recaptcha or Turnstile token is required')
  }

  return doPost2(`/password-reset/request`, payload)
}

// step 2 they confirm the request by supplying the token they were emailed
export const resetPassword = async (newPassword, confirmNewPassword, token) => {
  try {
    let payload = {
      newPassword: newPassword,
      confirmNewPassword: confirmNewPassword,
      token: token,
    }

    const response = await doPost2(`/password-reset/confirm`, payload)

    return response.text().then(data => {
      if (response.status !== 200) {
        // return {
        //     status: response.status,
        //     success: response.ok,
        //     detail: isJSON(data) ? JSON.parse(data) : data
        // }
        return new Promise((resolve, reject) => {
          reject({
            status: response.status,
            success: response.ok,
            detail: isJSON(data) ? JSON.parse(data) : data,
          })
        })
      } else {
        return {
          status: response.status,
          success: response.ok,
          detail: isJSON(data) ? JSON.parse(data) : data,
        }
      }
    })
  } catch (e) {
    return new Promise((resolve, reject) => {
      // eslint-disable-line no-unused-vars
      reject({
        success: false,
        status: -1,
        message:
          'Unable to communicate with server. Please check your internet connection.',
      })
    })
  }
}

export const getToken = () => {
  return doPost(`/user/legacyToken`)
}

export const sendEmailVerification = () => {
  return doPost(`/user/sendVerificationMail`)
}

export const deleteAccount = async () => {
  return await doPost(`/user/delete`)
}

export const verifyEmailAddress = async token => {
  try {
    const response = await doGet2(`/user/verify/${token}`, {})

    return response.text().then(data => {
      if (response.status !== 200) {
        return new Promise((resolve, reject) => {
          resolve({
            status: response.status,
            success: response.ok,
            detail: isJSON(data) ? JSON.parse(data) : data,
          })
        })
      } else {
        return {
          status: response.status,
          success: response.ok,
          detail: isJSON(data) ? JSON.parse(data) : data,
        }
      }
    })
  } catch (e) {
    return new Promise((resolve, reject) => {
      // eslint-disable-line no-unused-vars
      reject({
        success: false,
        status: -1,
        message:
          'Unable to communicate with server. Please check your internet connection.',
      })
    })
  }
}

export const getCompletedBreaks = breakIds => {
  // console.log('brkids: ', breakIds)
  return doPost(`/user/specificCompletedBreaks`, breakIds)
}

export const getSweepsEntryCount = sweepsId => {
  return doGet(`/user/sweepsEntryCount/${sweepsId}`)
}
